exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-club-accredited-club-jsx": () => import("./../../../src/pages/club/accredited-club.jsx" /* webpackChunkName: "component---src-pages-club-accredited-club-jsx" */),
  "component---src-pages-club-code-of-conduct-jsx": () => import("./../../../src/pages/club/code-of-conduct.jsx" /* webpackChunkName: "component---src-pages-club-code-of-conduct-jsx" */),
  "component---src-pages-club-committee-jsx": () => import("./../../../src/pages/club/committee.jsx" /* webpackChunkName: "component---src-pages-club-committee-jsx" */),
  "component---src-pages-club-constitution-jsx": () => import("./../../../src/pages/club/constitution.jsx" /* webpackChunkName: "component---src-pages-club-constitution-jsx" */),
  "component---src-pages-club-history-jsx": () => import("./../../../src/pages/club/history.jsx" /* webpackChunkName: "component---src-pages-club-history-jsx" */),
  "component---src-pages-club-index-jsx": () => import("./../../../src/pages/club/index.jsx" /* webpackChunkName: "component---src-pages-club-index-jsx" */),
  "component---src-pages-club-membership-jsx": () => import("./../../../src/pages/club/membership.jsx" /* webpackChunkName: "component---src-pages-club-membership-jsx" */),
  "component---src-pages-club-pitches-jsx": () => import("./../../../src/pages/club/pitches.jsx" /* webpackChunkName: "component---src-pages-club-pitches-jsx" */),
  "component---src-pages-club-safeguarding-and-welfare-jsx": () => import("./../../../src/pages/club/safeguarding-and-welfare.jsx" /* webpackChunkName: "component---src-pages-club-safeguarding-and-welfare-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-girls-football-index-jsx": () => import("./../../../src/pages/girls-football/index.jsx" /* webpackChunkName: "component---src-pages-girls-football-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-international-cup-2025-jsx": () => import("./../../../src/pages/international-cup-2025.jsx" /* webpackChunkName: "component---src-pages-international-cup-2025-jsx" */),
  "component---src-pages-policies-equality-jsx": () => import("./../../../src/pages/policies/equality.jsx" /* webpackChunkName: "component---src-pages-policies-equality-jsx" */),
  "component---src-pages-policies-index-jsx": () => import("./../../../src/pages/policies/index.jsx" /* webpackChunkName: "component---src-pages-policies-index-jsx" */),
  "component---src-pages-policies-privacy-jsx": () => import("./../../../src/pages/policies/privacy.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-jsx" */),
  "component---src-pages-policies-procurement-jsx": () => import("./../../../src/pages/policies/procurement.jsx" /* webpackChunkName: "component---src-pages-policies-procurement-jsx" */),
  "component---src-pages-policies-safeguarding-jsx": () => import("./../../../src/pages/policies/safeguarding.jsx" /* webpackChunkName: "component---src-pages-policies-safeguarding-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-sponsorship-index-jsx": () => import("./../../../src/pages/sponsorship/index.jsx" /* webpackChunkName: "component---src-pages-sponsorship-index-jsx" */),
  "component---src-pages-squads-index-jsx": () => import("./../../../src/pages/squads/index.jsx" /* webpackChunkName: "component---src-pages-squads-index-jsx" */)
}

